/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { Modal } from 'bootstrap';
import { task, waitForEvent } from 'ember-concurrency';

// DO NOT USE DIRECTLY
export default class StandardModalBase extends Component {
  get focus() {
    return this.args.focus ?? 'true';
  }

  get keyboard() {
    return this.args.keyboard ?? 'true';
  }

  onDidInsert = task(async (element) => {
    new Modal(element).show();

    if (!this.args.onCancel) {
      return;
    }

    await waitForEvent(element, 'hidden.bs.modal');
    this.args.onCancel();
  });

  @action
  onWillDestroy(element) {
    this.onDidInsert.cancelAll(true);
    const modal = Modal.getInstance(element);
    // need to hide first then dispose because classes and styles were getting stuck on <body> for some reason without hide
    modal.hide();
    modal.dispose();
  }
}
