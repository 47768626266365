import { tracked } from '@glimmer/tracking';
import DataViewController from 'eflex-license-manager/utils/data-view-controller';
import DataView from 'eflex-license-manager/constants/data-view';

export default class LicensesController extends DataViewController {
  @tracked sortByField = 'createdAt';
  @tracked sortDirection = DataView.SORT_DESC;
  @tracked searchFields = ['licenseId', 'label', 'jobNumber'];
  @tracked totalRecords = 0;

  get sortableData() {
    return this.filteredLicenses;
  }

  get filteredLicenses() {
    return this.model.reject((mod) => mod.isNew || mod.isDeleted);
  }
}
