import Product from 'eflex-license-manager/models/product';
import { belongsTo } from '@ember-data/model';

class Kitting extends Product {
  @belongsTo('stationFeature', { async: false, inverse: null }) features;

  name = 'kitting';
  label = 'Kitting';
}

export default Kitting;
