import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';

export default class ApplicationController extends Controller {
  @service session;
  @service currentUser;

  @tracked navCollapsed = true;

  logout = task(waitFor(async () => {
    await this.session.invalidate();
  }));
}
