import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import moment from 'moment';
import { buildValidations, validator } from '@eflexsystems/ember-tracked-validations';
import { eflexFeatures } from 'eflex-license-manager/constants/eflex-features';

@buildValidations({
  organization: validator('belongs-to'),
  creator: validator('presence', true),
  stationLimit: validator('presence', true),
  expirationDate: validator('presence', true),
  label: validator('presence', true),
})
class License extends Model {
  @attr('string') licenseId;
  @attr('string') label;
  @attr('string') jobNumber;
  @attr('number') stationLimit;
  @attr('date', {
    defaultValue() {
      return new Date();
    },
  }) expirationDate;

  @attr('boolean', { defaultValue: true }) sendWarnings;
  @attr('objectId') creator;
  @attr('date', {
    defaultValue() {
      return new Date();
    },
  }) createdAt;

  @attr('date', {
    defaultValue() {
      return new Date();
    },
  }) updatedAt;

  @attr('string') notes;

  @belongsTo('organization', { async: false, inverse: 'licenses' }) organization;
  @hasMany('changelog', { async: false, cascadeDelete: true, inverse: null }) changelogs;
  @hasMany('feature', { async: false, inverse: null }) features;

  get isDirty() {
    return this.hasDirtyAttributes ||
      this.changelogs.isAny('isDirty') ||
      this.features.isAny('isDirty') ||
      this.features.isAny('isDeleted');
  }

  get level1Features() {
    return this.nonDeletedFeatures?.filter(feature => feature.group === 'level-1');
  }

  get level2Features() {
    return this.nonDeletedFeatures?.filter(feature => feature.group === 'level-2');
  }

  get level3Features() {
    return this.nonDeletedFeatures?.filter(feature => feature.group === 'level-3');
  }

  get addonFeatures() {
    return this.nonDeletedFeatures?.filter(feature => feature.group === 'addon');
  }

  get nonDeletedFeatures() {
    return this.validFeatures?.filter(feature => !feature.isDeleted);
  }

  get validFeatures() {
    const keys = eflexFeatures.mapBy('key');
    return this.features.filter((f) => keys.includes(f.key));
  }

  get expirationThreshold() {
    return this.organization?.expirationThreshold;
  }

  get daysTillExpired() {
    return moment(this.expirationDate).diff(moment(), 'days') + 1;
  }

  get expirationWarning() {
    if (this.expired) {
      return false;
    }
    return moment(this.expirationDate).subtract(this.expirationThreshold, 'days').isBefore(moment());
  }

  get expired() {
    return moment(this.expirationDate).isBefore(moment());
  }

  get expiredOrWarning() {
    return this.expired || this.expirationWarning;
  }

  get sortedChangelogs() {
    return this.changelogs.sortBy('createdAt');
  }

  async save(options) {
    await super.save(options);

    this.features.toArray().forEach(feature => {
      if (!feature.isDirty) {
        return;
      }

      feature.send('willCommit');

      const internalModel = feature._internalModel._recordData;

      Object.assign(internalModel._data, internalModel._attributes);
      internalModel._attributes = Object.create(null);

      feature.send('didCommit');
    });

    this.changelogs.toArray().forEach(changelog => {
      if (!changelog.isDirty) {
        return;
      }

      changelog.save();
    });
  }

  rollbackAttributes() {
    this.features.toArray().forEach(feature => {
      if (feature.hasDirtyAttributes) {
        feature.rollbackAttributes();
      }
    });

    this.changelogs.toArray().forEach(changelog => {
      if (changelog.hasDirtyAttributes) {
        changelog.rollbackAttributes();
      }
    });

    super.rollbackAttributes(...arguments);
  }
}

export default License;
