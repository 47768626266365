import Service, { inject as service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';

export default class OrganizationRepo extends Service {
  @service store;
  @tracked searchFields = ['name'];

  search = task(waitFor(async (searchTerm) => {
    const searchQueryOrs = this.searchFields.map((field) => ({
      [field]: new RegExp(searchTerm),
    }));

    return await this.store.query('organization', { $or: searchQueryOrs });
  }));
}
