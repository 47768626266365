import { inject as service } from '@ember/service';
import { camelize } from '@ember/string';
import { pluralize } from 'ember-inflector';
import { buildWaiter } from '@ember/test-waiters';
import { ObjectId } from 'bson';
import { getEmbeddedAlwaysRelationships, getCascadeDeletedRecords } from 'eflex-license-manager/utils/ember-data';
import Adapter from '@ember-data/adapter';
//TODO: adapter/error is deprecated, need to use native Error
import AdapterError, { InvalidError } from '@ember-data/adapter/error';

const SORT_ASC = 1;
const SORT_DESC = -1;

const waiter = buildWaiter('realm-adapter');

export default class ApplicationAdapter extends Adapter {
  @service store;
  @service mongodbRealm;
  @service session;

  adapterName = 'Realm Adapter';

  handleResponse(status) {
    if (status === 401 && this.session.isAuthenticated) {
      this.session.invalidate();
    }
    return super.handleResponse(...arguments);
  }

  _getCollectionName(type) {
    return camelize(pluralize(type.modelName));
  }

  _getCollection(type) {
    return this.mongodbRealm.getCollection(this._getCollectionName(type));
  }

  generateIdForRecord() {
    return new ObjectId().toString();
  }

  async findRecord(store, type, id) {
    const waitToken = waiter.beginAsync();

    try {
      return await this._findByMatch(
        store,
        type,
        {
          _id: new ObjectId(id),
        },
        type,
      );
    } catch (e) {
      console.error(e);
      throw new AdapterError([e], `${this.adapterName} - findRecord failed.`);
    } finally {
      waiter.endAsync(waitToken);
    }
  }

  async findMany(store, type, ids) {
    const waitToken = waiter.beginAsync();

    try {
      return await this._findByMatch(store, type, {
        _id: {
          $in: ids.map((id) => new ObjectId(id)),
        },
      });
    } catch (e) {
      console.error(e);
      throw new AdapterError([e], `${this.adapterName} - findMany failed.`);
    } finally {
      waiter.endAsync(waitToken);
    }
  }

  async findAll(store, type, sinceToken, snapshotArray) {
    const waitToken = waiter.beginAsync();

    try {
      snapshotArray.adapterOptions ??= {};

      const pipeline = this._buildRelationshipPipeline(store, type, snapshotArray.adapterOptions);

      const [response] = await this.mongodbRealm.aggregate(this._getCollectionName(type), pipeline);
      return response;
    } catch (e) {
      console.error(e);
      throw new AdapterError([e], `${this.adapterName} - findAll failed.`);
    } finally {
      waiter.endAsync(waitToken);
    }
  }

  async query(store, type, query, recordArray, options) {
    const waitToken = waiter.beginAsync();

    try {
      options.adapterOptions ??= {};

      return await this._findByMatch(store, type, query, options.adapterOptions);
    } catch (e) {
      console.error(e);
      throw new AdapterError([e], `${this.adapterName} - query failed.`);
    } finally {
      waiter.endAsync(waitToken);
    }
  }

  async _findByMatch(store, type, match, options) {
    // TODO: this should probably have the pagination as the first part of agg so all relationships aren't loaded at once
    let pipeline = [
      {
        $match: match,
      },
    ];

    if (options?.sortByField != null) {
      let sortDirection;
      if (options.sortDirection != null && options.sortDirection === 'ASC') {
        sortDirection = SORT_ASC;
      } else {
        sortDirection = SORT_DESC;
      }

      pipeline = this._addPipelineStages(pipeline, [
        {
          $sort: {
            [options.sortByField]: sortDirection,
          },
        },
      ]);
    }

    pipeline.push(...this._buildRelationshipPipeline(store, type, options));

    const [response] = await this.mongodbRealm.aggregate(this._getCollectionName(type), pipeline);
    return response;
  }

  _buildPaginationPipeline(page, perPage = 30) {
    page = page > 0 ? page : 1;

    return [
      {
        $skip: perPage * (page - 1),
      },
      {
        $limit: perPage,
      },
    ];
  }

  _buildEmbeddedAlwaysPipeline(embeddedAlwaysRelationships = {}) {
    let pipeline = [];

    for (const [name, info] of Object.entries(embeddedAlwaysRelationships)) {
      if (['oneToOne', 'oneToNone'].includes(info.relationshipType)) {
        pipeline.push({ $set: { [name]: `$${name}._id` } });
      } else {
        pipeline.push({
          $set: {
            [name]: {
              $map: {
                input: `$${name}`,
                as: 'embeddedDoc',
                in: '$$embeddedDoc._id',
              },
            },
          },
        });
      }
    }

    return pipeline;
  }

  _buildRelationshipPipeline(store, type, options) {
    let paginationPipeline = null;
    if (options?.page != null && options?.perPage != null) {
      paginationPipeline = this._buildPaginationPipeline(options.page, options.perPage);
    }

    const embeddedAlwaysRelationships = getEmbeddedAlwaysRelationships(store, type);
    const embeddedAlwaysPipeline = this._buildEmbeddedAlwaysPipeline(embeddedAlwaysRelationships);

    let allFacets = {
      __data: this._addPipelineStages([{ $match: {} }], paginationPipeline, embeddedAlwaysPipeline),
      __meta: [
        {
          $count: 'recordCount',
        },
      ],
    };

    const { facets, collectionUnwrapper } = this._buildRelationshipFacets(store, type, embeddedAlwaysRelationships);

    if (facets != null) {
      allFacets = Object.assign(allFacets, facets);
    }

    const parentCollection = this._getCollectionName(type);
    collectionUnwrapper[parentCollection] = '$__data';
    collectionUnwrapper.meta = {
      $arrayElemAt: ['$__meta', 0],
    };

    return [
      {
        $facet: allFacets,
      },
      {
        $replaceRoot: {
          newRoot: collectionUnwrapper,
        },
      },
    ];
  }

  _buildRelationshipFacets(store, type, embeddedAlwaysRelationships) {
    const facets = {};
    const collectionUnwrapper = {};

    type.eachRelationship(
      function (name, descriptor) {
        const relationshipType = type.determineRelationshipType(descriptor, store);
        let relatedCollection = camelize(descriptor.type);

        if (!['oneToOne', 'oneToNone'].includes(relationshipType)) {
          relatedCollection = pluralize(descriptor.type);
        }

        let embeddedAlwaysRelationship = embeddedAlwaysRelationships[name];
        if (embeddedAlwaysRelationship) {
          facets[relatedCollection] = this.adapter._getEmbeddedAlwaysFacet(
            relatedCollection,
            embeddedAlwaysRelationship,
          );
          collectionUnwrapper[relatedCollection] = `$${relatedCollection}`;
          return;
        }

        switch (relationshipType) {
          case 'manyToOne': {
            if (descriptor.options?.inverse == null) {
              throw new Error(`inverse must be defined for manyToOne relationship: ${name}`);
            }

            facets[relatedCollection] = this.adapter._getManyToOneFacet(
              relatedCollection,
              descriptor.options.inverse,
              '_id',
            );
            break;
          }

          case 'oneToMany': {
            facets[relatedCollection] = this.adapter._getOneToManyFacet(relatedCollection, name);
            break;
          }

          case 'manyToNone': {
            facets[relatedCollection] = this.adapter._getManyToNoneFacet(relatedCollection, descriptor.name, '_id');
            break;
          }

          case 'oneToOne': {
            facets[relatedCollection] = this.adapter._getOneToOneFacet(relatedCollection, name);
            break;
          }
        }

        //TODO: manyToMany?
        //TODO: oneToNone?
        collectionUnwrapper[relatedCollection] = `$${relatedCollection}`;
      },
      { adapter: this, store, embeddedAlwaysRelationships },
    );

    return {
      facets,
      collectionUnwrapper,
    };
  }

  _getEmbeddedAlwaysFacet(localField, embeddedAlwaysRelationship) {
    let facet = [{ $match: { [localField]: { $exists: true, $ne: null } } }];

    if (!['oneToOne', 'oneToNone'].includes(embeddedAlwaysRelationship.relationshipType)) {
      facet.push({
        $unwind: `$${localField}`,
      });
    }

    facet.push({
      $replaceRoot: {
        newRoot: `$${localField}`,
      },
    });

    return facet;
  }

  _getManyToOneFacet(from, foreignField, localField) {
    return [
      {
        $lookup: {
          from,
          localField,
          foreignField,
          as: '__relatedObj',
        },
      },
      {
        $unwind: '$__relatedObj',
      },
      {
        $replaceWith: '$__relatedObj',
      },
    ];
  }

  _getOneToManyFacet(from, localField) {
    return [
      {
        $lookup: {
          from,
          localField,
          foreignField: '_id',
          as: '__relatedObj',
        },
      },
      {
        $unwind: '$__relatedObj',
      },
      {
        $replaceWith: '$__relatedObj',
      },
    ];
  }

  _getManyToNoneFacet(from, localField, foreignField) {
    return [
      {
        $unwind: `$${localField}`,
      },
      {
        $lookup: {
          from,
          localField,
          foreignField,
          as: '__relatedObj',
        },
      },
      {
        $unwind: '$__relatedObj',
      },
      {
        $replaceWith: '$__relatedObj',
      },
    ];
  }

  _getOneToOneFacet(from, localField) {
    return [
      {
        $lookup: {
          from,
          localField,
          foreignField: '_id',
          as: '__relatedObj',
        },
      },
      {
        $replaceWith: '$__relatedObj',
      },
    ];
  }

  _addPipelineStages(pipeline, ...stages) {
    return pipeline.concat(stages.flat().compact());
  }

  async createRecord(store, type, snapshot) {
    const waitToken = waiter.beginAsync();

    const json = this.serialize(snapshot, { includeId: true });

    try {
      const collection = this._getCollection(type);
      const result = await collection.findOneAndUpdate(
        { _id: json._id },
        { $set: json },
        {
          upsert: true,
          returnNewDocument: true,
        },
      );

      return this._handleResult(result, type, 'Unable to create record.');
    } catch (e) {
      console.error(e);
      throw new AdapterError([e], `${this.adapterName} - createRecord failed.`);
    } finally {
      waiter.endAsync(waitToken);
    }
  }

  async updateRecord(store, type, snapshot) {
    const waitToken = waiter.beginAsync();

    const json = this.serialize(snapshot, { includeId: true });

    try {
      const collection = this._getCollection(type);
      const result = await collection.findOneAndUpdate({ _id: json._id }, { $set: json }, { returnNewDocument: true });

      return this._handleResult(result, type, 'Unable to update record.');
    } catch (e) {
      console.error(e);
      throw new AdapterError([e], `${this.adapterName} - updateRecord failed.`);
    } finally {
      waiter.endAsync(waitToken);
    }
  }

  async deleteRecord(store, type, snapshot) {
    const waitToken = waiter.beginAsync();

    try {
      const recordsToDestroy = getCascadeDeletedRecords(snapshot.record);

      const collection = this._getCollection(type);
      const result = await collection.deleteOne({ _id: new ObjectId(snapshot.id) });
      const response = this._handleResult(result, type, 'Unable to delete record.');
      await Promise.all(recordsToDestroy.invoke('destroyRecord'));

      return response;
    } catch (e) {
      console.error(e);
      throw new AdapterError([e], `${this.adapterName} - deleteRecord failed.`);
    } finally {
      waiter.endAsync(waitToken);
    }
  }

  _handleResult(result, type, errorMessage) {
    if (result != null) {
      return Promise.resolve({ [type.modelName]: result });
    } else {
      console.error(`${this.adapterName} - ${errorMessage}`);
      throw new InvalidError([
        {
          detail: errorMessage,
          source: {
            pointer: 'data',
          },
        },
      ]);
    }
  }
}
