import Product from 'eflex-license-manager/models/product';
import { belongsTo } from '@ember-data/model';

class Jem extends Product {
  @belongsTo('stationFeature', { async: false, inverse: null }) features;

  name = 'jem';
  label = 'JEM';
}

export default Jem;
