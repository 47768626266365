import { tracked } from '@glimmer/tracking';

const eflexFeatures = [
  // Level 1
  {
    key: 'jem',
    label: 'JEM',
    group: 'level-1',
  },
  // Level 2
  {
    key: 'barcode',
    label: 'Barcode',
    group: 'level-2',
  },
  {
    key: 'image-capture',
    label: 'Image Capture',
    group: 'level-2',
  },
  {
    key: 'part-marriage',
    label: 'Part Marriage',
    group: 'level-2',
  },
  {
    key: 'print',
    label: 'Print',
    group: 'level-2',
  },
  {
    key: 'process-data',
    label: 'Process Data',
    group: 'level-2',
  },
  {
    key: 'push-to-schedule',
    label: 'Push To Schedule',
    group: 'level-2',
  },
  {
    key: 'serial-number-transfer',
    label: 'Serial Number Transfer',
    group: 'level-2',
  },
  {
    key: 'station-prereqs',
    label: 'Station Prereqs',
    group: 'level-2',
  },
  {
    key: 'wie-approval',
    label: 'WIE Approval',
    group: 'level-2',
  },
  // Level 3
  {
    key: 'cobot',
    label: 'Cobot',
    group: 'level-3',
  },
  {
    key: 'error-proofing',
    label: 'Error Proofing',
    group: 'level-3',
  },
  {
    key: 'multispindle',
    label: 'Multispindle',
    group: 'level-3',
  },
  {
    key: 'node-red',
    label: 'Node Red',
    group: 'level-3',
  },
  {
    key: 'pick',
    label: 'Pick',
    group: 'level-3',
  },
  {
    key: 'plc',
    label: 'PLC',
    group: 'level-3',
  },
  {
    key: 'torque',
    label: 'Torque',
    group: 'level-3',
  },
  {
    key: 'evision',
    label: 'Vision',
    group: 'level-3',
  },
  {
    key: 'opc-ua',
    label: 'OPC UA',
    group: 'level-3',
  },
  // Addons
  {
    key: 'andon',
    label: 'Andon',
    group: 'addon',
  },
  {
    key: 'bi',
    label: 'Business Intelligence',
    group: 'addon',
  },
  {
    key: 'component-based',
    label: 'BOM (Component) Based',
    group: 'addon',
  },
  {
    key: 'edhr',
    label: 'eDHR',
    group: 'addon',
    hardware: ['edhr'],
  },
  {
    key: 'kinetic',
    label: 'Kinetic',
    group: 'addon',
  },
  {
    key: 'light-guide',
    label: 'Light Guide',
    group: 'addon',
  },
  {
    key: 'oee',
    label: 'OEE',
    group: 'addon',
  },
  {
    key: 'pick-banner',
    label: 'Pick - Banner PTL-110',
    group: 'addon',
  },
  {
    key: 'station-version-control',
    label: 'Station Version Control',
    group: 'addon',
  },
  {
    key: 'track-and-trace',
    label: 'TNT',
    group: 'addon',
  },
];

class Feature {
  @tracked license;
  @tracked key;

  constructor(feature, license) {
    Object.assign(this, feature);
    this.license = license;
  }

  get enabled() {
    const licFeature = this.license.nonDeletedFeatures.findBy('key', this.key);
    return licFeature?.enabled;
  }
}

const getFeatureObjects = (license) =>
  eflexFeatures.map(function (feature) {
    return new Feature(feature, license);
  });

export default getFeatureObjects;

export { getFeatureObjects, eflexFeatures };
