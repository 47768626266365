import EmberRouter from '@ember/routing/router';
import config from 'eflex-license-manager/config/environment';

export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
}

// eslint-disable-next-line array-callback-return
Router.map(function () {
  this.route('login');
  this.route('handleOauth');

  this.route('organizations');
  this.route('organization', { path: '/organizations/:id' });

  this.route('licenses');
  this.route('license', { path: '/licenses/:id' });

  this.route('legacyLicenses');
  this.route('legacyLicense', { path: '/legacyLicenses/:id' });
});
