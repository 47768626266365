import Route from '@ember/routing/route';
import * as Realm from 'realm-web';
import { inject as service } from '@ember/service';

export default class HandleOauthRoute extends Route {
  @service session;

  beforeModel() {
    return this.session.prohibitAuthentication('index');
  }

  afterModel() {
    Realm.handleAuthRedirect();
  }
}
