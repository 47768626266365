/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { waitFor } from '@ember/test-waiters';
import { task } from 'ember-concurrency';
import moment from 'moment';
import { eflexFeatures } from 'eflex-license-manager/constants/eflex-features';

class LicenseDisplay extends Component {
  @service store;
  @service currentUser;
  @service organizationRepo;
  @service licenseEncrypter;
  @service router;

  @tracked searchTerm;
  @tracked showLicenseModal = false;
  @tracked showChangelogModal = false;
  @tracked showDeleteConfirmModal = false;
  @tracked isEditing = false;
  @tracked isConfirming = false;
  @tracked currentChangelog = null;
  @tracked currentLicense = null;

  get totalRecords() {
    return this.args.totalRecords;
  }

  get showOrganization() {
    return isEmpty(this.args.parentOrganization);
  }

  _reset() {
    if (!this.args.singleLicense) {
      this.currentLicense = null;
    }

    Object.assign(this, {
      currentChangelog: null,
      showLicenseModal: false,
      showChangelogModal: false,
      showDeleteConfirmModal: false,
      isConfirming: false,
      isEditing: false,
    });
  }

  _saveLicense = task(waitFor(async () => {
    try {
      if (this.currentChangelog != null) {
        await this.currentChangelog.save();
      }

      await this.currentLicense.save();

      this._reset();
    } catch (e) {
      console.error(e);
    }
  }));

  _getNewLicenseId() {
    return moment().format('YYMMDDHHmmss');
  }

  _addLicenseFeature(key) {
    const feature = this.store.createRecord('feature', {
      key,
      group: this._getFeatureGroupByKey(key),
    });

    this.currentLicense.features.addObject(feature);
  }

  _removeLicenseFeature(key) {
    const feature = this.currentLicense.nonDeletedFeatures.findBy('key', key);
    feature.deleteRecord();
  }

  _addLicenseFeatureGroup(group) {
    const existingFeatures = this.currentLicense.features.filter(feature => feature.group === group);
    existingFeatures.invoke('rollbackAttributes');

    const existingFeatureKeys = existingFeatures.mapBy('key');
    eflexFeatures.filter(feature => feature.group === group).forEach((feature) => {
      if (!existingFeatureKeys.includes(feature.key)) {
        const newFeature = this.store.createRecord('feature', {
          key: feature.key,
          group,
        });

        this.currentLicense.features.addObject(newFeature);
      }
    });
  }

  _removeLicenseFeatureGroup(group) {
    const existingFeatures = this.currentLicense.features.filter(feature => feature.group === group);
    existingFeatures.toArray().invoke('deleteRecord');
  }

  _getFeatureGroupByKey(key) {
    return eflexFeatures.findBy('key', key)?.group;
  }

  @action
  addLicense() {
    const license = this.store.createRecord('license', {
      licenseId: this._getNewLicenseId(),
      creator: this.currentUser.id,
    });

    const changelog = this.store.createRecord('changelog', {
      creator: this.currentUser.id,
      creatorName: this.currentUser.creatorName,
      notes: 'License Created',
    });

    license.changelogs.addObject(changelog);

    if (this.args.parentOrganization != null) {
      license.organization = this.args.parentOrganization;
    }

    Object.assign(this, {
      currentLicense: license,
      showLicenseModal: true,
    });
  }

  @action
  featureUpdated(key, enabled) {
    if (enabled) {
      this._addLicenseFeature(key);
    } else {
      this._removeLicenseFeature(key);
    }
  }

  @action
  featureGroupUpdated(group, enabled) {
    if (enabled) {
      this._addLicenseFeatureGroup(group);
    } else {
      this._removeLicenseFeatureGroup(group);
    }
  }

  @action
  edit(license) {
    Object.assign(this, {
      currentLicense: license,
      showLicenseModal: true,
      isEditing: true,
    });
  }

  @action
  showChangelogs(license) {
    Object.assign(this, {
      currentLicense: license,
      showChangelogModal: true,
    });
  }

  @action
  hideChangelogs() {
    this._reset();
  }

  @action
  async continueSave() {
    if (!this.isEditing) {
      await this._saveLicense.perform(true);
      this.router.refresh();
      return;
    }

    const changelog = this.store.createRecord('changelog', {
      creator: this.currentUser.id,
      creatorName: this.currentUser.creatorName,
    });

    this.currentLicense.changelogs.addObject(changelog);
    this.currentLicense.updatedAt = changelog.createdAt;

    Object.assign(this, {
      currentChangelog: changelog,
      isConfirming: true,
    });
  }

  @action
  async confirmSave() {
    await this._saveLicense.perform();
  }

  @action
  async hideAddEditModal() {
    this.showLicenseModal = false;

    if (this.currentLicense?.isNew) {
      await this.currentLicense.destroyRecord({});
    } else {
      this.currentLicense.rollbackAttributes();
    }

    if (this.currentChangelog?.isNew) {
      await this.currentChangelog.destroyRecord({});
    }

    this._reset();
  }

  @action
  delete(license) {
    Object.assign(this, {
      currentLicense: license,
      showDeleteConfirmModal: true,
    });
  }

  @action
  async confirmDelete() {
    await this.currentLicense.destroyRecord({});
    this._reset();

    if (this.args.singleLicense) {
      this.router.transitionTo('licenses');
    }
  }

  @action
  cancelDelete() {
    this._reset();
  }

  @action
  async download(license) {
    await this.licenseEncrypter.downloadLicense.perform(license);
  }
}

export default LicenseDisplay;
