import { inject as service } from '@ember/service';
import AuthenticatedRoute from 'eflex-license-manager/utils/authenticated-route';

export default class LegacyLicenseRoute extends AuthenticatedRoute {
  @service store;

  model(params) {
    return this.store.findRecord('legacyLicense', params.id);
  }
}
