import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import * as Realm from 'realm-web';
import config from 'eflex-license-manager/config/environment';

export default class MongoDbRealm extends Service {
  @service session;

  @tracked realmAppId = null;
  @tracked realmApp = null;
  @tracked mongoServiceName = null;
  @tracked mongoDbName = null;
  @tracked apiKeyAuth = false;

  get currentUser () {
    return this.realmApp.currentUser;
  }

  get mongoClient() {
    return this.currentUser.mongoClient(this.mongoServiceName);
  }

  constructor() {
    super(...arguments);
    const { realmAppId, mongoServiceName, mongoDbName, apiKeyAuth } = config.mongodbRealm;
    this.realmAppId = realmAppId;
    this.mongoServiceName = mongoServiceName;
    this.mongoDbName = mongoDbName;
    this.apiKeyAuth = apiKeyAuth;

    this._initRealmApp();
  }

  _initRealmApp() {
    this.realmApp = Realm.App.getApp(this.realmAppId);
  }

  getCollection(name) {
    return this.mongoClient.db(this.mongoDbName).collection(name);
  }

  async logIn(credentials) {
    return await this.realmApp.logIn(credentials);
  }

  async logout() {
    return await this.realmApp.currentUser.logOut();
  }

  async aggregate(collectionName, pipeline) {
    return await this.realmApp.currentUser.callFunction('aggregate', this.mongoDbName, collectionName, pipeline);
  }
}
