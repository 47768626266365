const EncryptionKey = `-----BEGIN PUBLIC KEY----- \
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA98IPEvfCsAzakkWIBxX7 \
kfFiG1tctdQLat8M3tHjqcIi2TL5ONCZehEjxWM54UOObWyHoflaUBZT9+ljlTYb \
SchUjAtL5gF2ar3feqiS9W0K+L37t4aMl4WEOXaZCBw5SI1+RtggFvEotSUce6rj \
VlIwIr4cx8Eb+ejUGD9mdurGvLk1xE5CEhn4HiFluibeqBgTLrsZm+0ktqM3sxeI \
e0PJ39Ar4/q0LEuG4eqzYZNREQVpI8Cmb+YvVv/Cx5gk5Jhp73M8/6Xd4PZ2xPAo \
TjAqTQtn8U71SEQP28/rSjPNVRdaV6zB0dcAl4byhozvEyJECaBRJv8omN5h8JyK \
RwIDAQAB \
-----END PUBLIC KEY-----`;

export default EncryptionKey;
