/* import __COLOCATED_TEMPLATE__ from './date-time-picker.hbs'; */
import Control from 'ember-bootstrap/components/bs-form/element/control';
import { action } from '@ember/object';

export default class DateTimePickerControl extends Control {
  datePicker = null;

  willDestroy() {
    super.willDestroy(...arguments);
    this.datePicker = null;
  }

  @action
  onDateChange([date]) {
    this.args.onChange(date);
  }

  @action
  openDatePicker(event) {
    event.stopPropagation();
    this.datePicker.open();
  }

  @action
  datePickerReady(selectedDates, dateStr, instance) {
    this.datePicker = instance;
  }
}
