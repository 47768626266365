import { inject as service } from '@ember/service';
import pProps from 'p-props';
import AuthenticatedRoute from 'eflex-license-manager/utils/authenticated-route';

export default class IndexRoute extends AuthenticatedRoute {
  @service store;

  model() {
    return pProps({
      licenses: this.store.findAll('license'),
      legacyLicenses: this.store.findAll('legacyLicense'),
    });
  }

  setupController(controller, { licenses, legacyLicenses }) {
    Object.assign(controller, { licenses, legacyLicenses });
  }
}
