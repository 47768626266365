import Service, { inject as service } from '@ember/service';

export default class CurrentUser extends Service {
  @service session;

  get userData() {
    return this.session.data.authenticated;
  }

  get id() {
    return this.userData.id;
  }

  get email() {
    return this.userData.profile.data.email;
  }

  get name() {
    return this.userData.profile.data.name;
  }

  get nameFromEmail() {
    return this.email.split('@')[0];
  }

  get displayName() {
    return this.name ?? this.nameFromEmail;
  }

  get creatorName() {
    return this.email ?? this.name;
  }
}
