import { registerDeprecationHandler } from '@ember/debug';
import config from 'eflex-license-manager/config/environment';

const SHOULD_THROW = config.environment !== 'production';
const SILENCED_DEPRECATIONS = new Set([
  'deprecate-array-prototype-extensions',
]);

registerDeprecationHandler((message, options, next) => {
  if (SILENCED_DEPRECATIONS.has(options.id)) {
    return;
  }

  if (SHOULD_THROW) {
    throw new Error(message);
  }

  next(message, options);
});
