import Product from 'eflex-license-manager/models/product';
import { belongsTo } from '@ember-data/model';

class Assembly extends Product {
  @belongsTo('assemblyFeature', { async: false, inverse: null }) features;

  name = 'assembly';
  label = 'Assembly';

  get featuresJson() {
    return {
      numberOfStations: this.features.numberOfStations,
      statisticalReporting: this.features.statisticalReporting,
    };
  }
}

export default Assembly;
