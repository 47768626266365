import Model, { attr } from '@ember-data/model';
import moment from 'moment/moment';

class Product extends Model {
  @attr('boolean', { defaultValue: false }) enabled;
  @attr('date', { defaultValue() { return new Date(); } }) expirationDate;

  name;
  label;
  expirationThreshold = 30;

  get expirationWarning() {
    if (this.expired) {
      return false;
    }
    return this.enabled && moment(this.expirationDate).subtract(this.expirationThreshold, 'days').isBefore(moment());
  }

  get expired() {
    return this.enabled && moment(this.expirationDate).isBefore(moment());
  }

  get expiredOrWarning() {
    return this.expired || this.expirationWarning;
  }

  get daysTillExpired() {
    return moment(this.expirationDate).diff(moment(), 'days') + 1;
  }

  get json() {
    return {
      enabled: this.enabled,
      expirationDate: this.expirationDate,
      features: this.featuresJson,
    };
  }

  get featuresJson() {
    return {
      numberOfStations: this.features?.numberOfStations,
    };
  }

  get isDirty() {
    return this.hasDirtyAttributes ||
      this.features.isDirty;
  }

  rollbackAttributes() {
    if (this.features.hasDirtyAttributes) {
      this.features.rollbackAttributes();
    }

    super.rollbackAttributes(...arguments);
  }
}

export default Product;
