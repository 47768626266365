import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';

export default class IndexController extends Controller {
  @tracked licenses;
  @tracked legacyLicenses;

  get warningLicenses() {
    return this.licenses.filter((model) => model.expirationWarning === true);
  }

  get expiredLicenses() {
    return this.licenses.filter((model) => model.expired === true);
  }

  get warningLegacyLicenses() {
    return this.legacyLicenses.filter((model) => model.hasProductExpirationWarning === true);
  }

  get expiredLegacyLicenses() {
    return this.legacyLicenses.filter((model) => model.hasExpiredProduct === true);
  }
}
