// This "type definition" import comment provides auto-complete, go-to-def, etc.
// for Ember's internals throughout your application, so don't remove it!
/**
  @typedef {import('ember-source/types')} Types
*/

import { isTesting, importSync, isDevelopingApp, macroCondition } from '@embroider/macros';

// workaround bug in ember-data
if (macroCondition(isDevelopingApp() && !isTesting())) {
  const { registerTestImplementation } = importSync('@ember/test');

  registerTestImplementation({
    Test: {
      registerWaiter: (fn) => fn(),
    },
  });
}

if (macroCondition(isDevelopingApp() || isTesting())) {
  importSync('eflex-license-manager/deprecation-workflow');
}

import 'bootstrap';
import Application from '@ember/application';
import Resolver from 'ember-resolver';
import loadInitializers from 'ember-load-initializers';
import config from 'eflex-license-manager/config/environment';

export default class App extends Application {
  name = 'eflex-license-manager';
  modulePrefix = config.modulePrefix;
  podModulePrefix = config.podModulePrefix;
  Resolver = Resolver;
}

loadInitializers(App, config.modulePrefix);
