import Model, { attr, hasMany } from '@ember-data/model';
import { buildValidations, validator } from '@eflexsystems/ember-tracked-validations';

@buildValidations({
  name: validator('presence', true),
  creator: validator('presence', true),
  expirationThreshold: validator('number', {
    gte: 30,
    allowString: true,
    allowNone: false,
  }),
})
class Organization extends Model {
  @attr('string') name;
  @attr('objectId') creator;
  @attr('date', { defaultValue() { return new Date(); } }) createdAt;
  @attr('date', { defaultValue() { return new Date(); } }) updatedAt;
  @attr('string') notes;
  @attr('number', { defaultValue: 30 }) expirationThreshold;

  @hasMany('license', { async: false, cascadeDelete: true, inverse: 'organization' }) licenses;

  get isDirty() {
    return this.hasDirtyAttributes;
  }

  get licenseCount() {
    return this.licenses.filter(license => !license.isDeleted).length;
  }
}

export default Organization;
