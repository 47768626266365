
import { importSync as i, macroCondition, getGlobalConfig } from '@embroider/macros';
let w = window;
let d = w.define;


i("./-embroider-implicit-modules.js");

d("eflex-license-manager/adapters/application", function(){ return i("eflex-license-manager/adapters/application.js");});
d("eflex-license-manager/app", function(){ return i("eflex-license-manager/app.js");});
d("eflex-license-manager/authenticators/mongodb-realm", function(){ return i("eflex-license-manager/authenticators/mongodb-realm.js");});
d("eflex-license-manager/config/environment", function(){ return i("eflex-license-manager/config/environment.js");});
d("eflex-license-manager/deprecation-workflow", function(){ return i("eflex-license-manager/deprecation-workflow.js");});
d("eflex-license-manager/models/analytics-feature", function(){ return i("eflex-license-manager/models/analytics-feature.js");});
d("eflex-license-manager/models/analytics", function(){ return i("eflex-license-manager/models/analytics.js");});
d("eflex-license-manager/models/assembly-feature", function(){ return i("eflex-license-manager/models/assembly-feature.js");});
d("eflex-license-manager/models/assembly", function(){ return i("eflex-license-manager/models/assembly.js");});
d("eflex-license-manager/models/changelog", function(){ return i("eflex-license-manager/models/changelog.js");});
d("eflex-license-manager/models/evision-feature", function(){ return i("eflex-license-manager/models/evision-feature.js");});
d("eflex-license-manager/models/evision", function(){ return i("eflex-license-manager/models/evision.js");});
d("eflex-license-manager/models/feature", function(){ return i("eflex-license-manager/models/feature.js");});
d("eflex-license-manager/models/jel", function(){ return i("eflex-license-manager/models/jel.js");});
d("eflex-license-manager/models/jem", function(){ return i("eflex-license-manager/models/jem.js");});
d("eflex-license-manager/models/kitting", function(){ return i("eflex-license-manager/models/kitting.js");});
d("eflex-license-manager/models/legacy-license", function(){ return i("eflex-license-manager/models/legacy-license.js");});
d("eflex-license-manager/models/license", function(){ return i("eflex-license-manager/models/license.js");});
d("eflex-license-manager/models/oee", function(){ return i("eflex-license-manager/models/oee.js");});
d("eflex-license-manager/models/organization", function(){ return i("eflex-license-manager/models/organization.js");});
d("eflex-license-manager/models/product", function(){ return i("eflex-license-manager/models/product.js");});
d("eflex-license-manager/models/quality", function(){ return i("eflex-license-manager/models/quality.js");});
d("eflex-license-manager/models/station-feature", function(){ return i("eflex-license-manager/models/station-feature.js");});
d("eflex-license-manager/models/track-and-trace", function(){ return i("eflex-license-manager/models/track-and-trace.js");});
d("eflex-license-manager/router", function(){ return i("eflex-license-manager/router.js");});
d("eflex-license-manager/serializers/analytics", function(){ return i("eflex-license-manager/serializers/analytics.js");});
d("eflex-license-manager/serializers/application", function(){ return i("eflex-license-manager/serializers/application.js");});
d("eflex-license-manager/serializers/assembly", function(){ return i("eflex-license-manager/serializers/assembly.js");});
d("eflex-license-manager/serializers/evision", function(){ return i("eflex-license-manager/serializers/evision.js");});
d("eflex-license-manager/serializers/jel", function(){ return i("eflex-license-manager/serializers/jel.js");});
d("eflex-license-manager/serializers/jem", function(){ return i("eflex-license-manager/serializers/jem.js");});
d("eflex-license-manager/serializers/kitting", function(){ return i("eflex-license-manager/serializers/kitting.js");});
d("eflex-license-manager/serializers/legacy-license", function(){ return i("eflex-license-manager/serializers/legacy-license.js");});
d("eflex-license-manager/serializers/license", function(){ return i("eflex-license-manager/serializers/license.js");});
d("eflex-license-manager/serializers/oee", function(){ return i("eflex-license-manager/serializers/oee.js");});
d("eflex-license-manager/serializers/quality", function(){ return i("eflex-license-manager/serializers/quality.js");});
d("eflex-license-manager/serializers/track-and-trace", function(){ return i("eflex-license-manager/serializers/track-and-trace.js");});
d("eflex-license-manager/services/current-user", function(){ return i("eflex-license-manager/services/current-user.js");});
d("eflex-license-manager/services/legacy-license-encrypter", function(){ return i("eflex-license-manager/services/legacy-license-encrypter.js");});
d("eflex-license-manager/services/license-encrypter", function(){ return i("eflex-license-manager/services/license-encrypter.js");});
d("eflex-license-manager/services/mongodb-realm", function(){ return i("eflex-license-manager/services/mongodb-realm.js");});
d("eflex-license-manager/services/organization-repo", function(){ return i("eflex-license-manager/services/organization-repo.js");});
d("eflex-license-manager/transforms/object-id", function(){ return i("eflex-license-manager/transforms/object-id.js");});
d("eflex-license-manager/services/moment", function(){ return i("eflex-license-manager/services/moment.js");});
d("eflex-license-manager/services/cookies", function(){ return i("eflex-license-manager/services/cookies.js");});
d("eflex-license-manager/initializers/ember-simple-auth", function(){ return i("eflex-license-manager/initializers/ember-simple-auth.js");});
d("eflex-license-manager/services/session", function(){ return i("eflex-license-manager/services/session.js");});
d("eflex-license-manager/session-stores/application", function(){ return i("eflex-license-manager/session-stores/application.js");});
d("eflex-license-manager/services/-portal", function(){ return i("eflex-license-manager/services/-portal.js");});
d("eflex-license-manager/validators/belongs-to", function(){ return i("eflex-license-manager/validators/belongs-to.js");});
d("eflex-license-manager/validators/collection", function(){ return i("eflex-license-manager/validators/collection.js");});
d("eflex-license-manager/validators/confirmation", function(){ return i("eflex-license-manager/validators/confirmation.js");});
d("eflex-license-manager/validators/date", function(){ return i("eflex-license-manager/validators/date.js");});
d("eflex-license-manager/validators/dependent", function(){ return i("eflex-license-manager/validators/dependent.js");});
d("eflex-license-manager/validators/ds-error", function(){ return i("eflex-license-manager/validators/ds-error.js");});
d("eflex-license-manager/validators/exclusion", function(){ return i("eflex-license-manager/validators/exclusion.js");});
d("eflex-license-manager/validators/format", function(){ return i("eflex-license-manager/validators/format.js");});
d("eflex-license-manager/validators/has-many", function(){ return i("eflex-license-manager/validators/has-many.js");});
d("eflex-license-manager/validators/inclusion", function(){ return i("eflex-license-manager/validators/inclusion.js");});
d("eflex-license-manager/validators/inline", function(){ return i("eflex-license-manager/validators/inline.js");});
d("eflex-license-manager/validators/length", function(){ return i("eflex-license-manager/validators/length.js");});
d("eflex-license-manager/validators/messages", function(){ return i("eflex-license-manager/validators/messages.js");});
d("eflex-license-manager/validators/number", function(){ return i("eflex-license-manager/validators/number.js");});
d("eflex-license-manager/validators/presence", function(){ return i("eflex-license-manager/validators/presence.js");});
d("eflex-license-manager/component-managers/glimmer", function(){ return i("eflex-license-manager/component-managers/glimmer.js");});
d("eflex-license-manager/services/-ensure-registered", function(){ return i("eflex-license-manager/services/-ensure-registered.js");});
d("eflex-license-manager/instance-initializers/global-ref-cleanup", function(){ return i("eflex-license-manager/instance-initializers/global-ref-cleanup.js");});
d("eflex-license-manager/initializers/load-bootstrap-config", function(){ return i("eflex-license-manager/initializers/load-bootstrap-config.js");});
d("eflex-license-manager/initializers/app-version", function(){ return i("eflex-license-manager/initializers/app-version.js");});
d("eflex-license-manager/data-adapter", function(){ return i("eflex-license-manager/data-adapter.js");});
d("eflex-license-manager/initializers/ember-data-data-adapter", function(){ return i("eflex-license-manager/initializers/ember-data-data-adapter.js");});
d("eflex-license-manager/adapters/-json-api", function(){ return i("eflex-license-manager/adapters/-json-api.js");});
d("eflex-license-manager/initializers/ember-data", function(){ return i("eflex-license-manager/initializers/ember-data.js");});
d("eflex-license-manager/instance-initializers/ember-data", function(){ return i("eflex-license-manager/instance-initializers/ember-data.js");});
d("eflex-license-manager/serializers/-default", function(){ return i("eflex-license-manager/serializers/-default.js");});
d("eflex-license-manager/serializers/-json-api", function(){ return i("eflex-license-manager/serializers/-json-api.js");});
d("eflex-license-manager/serializers/-rest", function(){ return i("eflex-license-manager/serializers/-rest.js");});
d("eflex-license-manager/services/store", function(){ return i("eflex-license-manager/services/store.js");});
d("eflex-license-manager/transforms/boolean", function(){ return i("eflex-license-manager/transforms/boolean.js");});
d("eflex-license-manager/transforms/date", function(){ return i("eflex-license-manager/transforms/date.js");});
d("eflex-license-manager/transforms/number", function(){ return i("eflex-license-manager/transforms/number.js");});
d("eflex-license-manager/transforms/string", function(){ return i("eflex-license-manager/transforms/string.js");});
d("eflex-license-manager/container-debug-adapter", function(){ return i("eflex-license-manager/container-debug-adapter.js");});
d("eflex-license-manager/templates/application", function(){ return i("eflex-license-manager/templates/application.hbs");});
d("eflex-license-manager/controllers/application", function(){ return i("eflex-license-manager/controllers/application.js");});
d("eflex-license-manager/routes/application", function(){ return i("eflex-license-manager/routes/application.js");});
d("eflex-license-manager/pods/handle-oauth/template", function(){ return i("eflex-license-manager/pods/handle-oauth/template.hbs");});
d("eflex-license-manager/pods/handle-oauth/route", function(){ return i("eflex-license-manager/pods/handle-oauth/route.js");});
d("eflex-license-manager/pods/index/template", function(){ return i("eflex-license-manager/pods/index/template.hbs");});
d("eflex-license-manager/pods/index/controller", function(){ return i("eflex-license-manager/pods/index/controller.js");});
d("eflex-license-manager/pods/index/route", function(){ return i("eflex-license-manager/pods/index/route.js");});
d("eflex-license-manager/pods/legacy-license/template", function(){ return i("eflex-license-manager/pods/legacy-license/template.hbs");});
d("eflex-license-manager/pods/legacy-license/controller", function(){ return i("eflex-license-manager/pods/legacy-license/controller.js");});
d("eflex-license-manager/pods/legacy-license/route", function(){ return i("eflex-license-manager/pods/legacy-license/route.js");});
d("eflex-license-manager/pods/legacy-licenses/template", function(){ return i("eflex-license-manager/pods/legacy-licenses/template.hbs");});
d("eflex-license-manager/pods/legacy-licenses/controller", function(){ return i("eflex-license-manager/pods/legacy-licenses/controller.js");});
d("eflex-license-manager/pods/legacy-licenses/route", function(){ return i("eflex-license-manager/pods/legacy-licenses/route.js");});
d("eflex-license-manager/pods/license/template", function(){ return i("eflex-license-manager/pods/license/template.hbs");});
d("eflex-license-manager/pods/license/controller", function(){ return i("eflex-license-manager/pods/license/controller.js");});
d("eflex-license-manager/pods/license/route", function(){ return i("eflex-license-manager/pods/license/route.js");});
d("eflex-license-manager/pods/licenses/template", function(){ return i("eflex-license-manager/pods/licenses/template.hbs");});
d("eflex-license-manager/pods/licenses/controller", function(){ return i("eflex-license-manager/pods/licenses/controller.js");});
d("eflex-license-manager/pods/licenses/route", function(){ return i("eflex-license-manager/pods/licenses/route.js");});
d("eflex-license-manager/pods/login/template", function(){ return i("eflex-license-manager/pods/login/template.hbs");});
d("eflex-license-manager/pods/login/controller", function(){ return i("eflex-license-manager/pods/login/controller.js");});
d("eflex-license-manager/pods/login/route", function(){ return i("eflex-license-manager/pods/login/route.js");});
d("eflex-license-manager/pods/organization/template", function(){ return i("eflex-license-manager/pods/organization/template.hbs");});
d("eflex-license-manager/pods/organization/controller", function(){ return i("eflex-license-manager/pods/organization/controller.js");});
d("eflex-license-manager/pods/organization/route", function(){ return i("eflex-license-manager/pods/organization/route.js");});
d("eflex-license-manager/pods/organizations/template", function(){ return i("eflex-license-manager/pods/organizations/template.hbs");});
d("eflex-license-manager/pods/organizations/controller", function(){ return i("eflex-license-manager/pods/organizations/controller.js");});
d("eflex-license-manager/pods/organizations/route", function(){ return i("eflex-license-manager/pods/organizations/route.js");});
d("eflex-license-manager/templates/error", function(){ return i("eflex-license-manager/templates/error.hbs");});

  if (macroCondition(getGlobalConfig().fastboot?.isRunning)) {
d("eflex-license-manager/instance-initializers/setup-fetch", function(){ return i("eflex-license-manager/instance-initializers/setup-fetch.js");});
  }




if (!runningTests) {
  i("../app").default.create({"name":"eflex-license-manager","version":"0.0.0+c5fe792f"});
}

