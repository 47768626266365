/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { isEmpty } from '@ember/utils';
import { inject as service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import { task, timeout } from 'ember-concurrency';
import getFeatureObjects from 'eflex-license-manager/constants/eflex-features';

class LicenseModal extends Component {
  @service store;
  @service organizationRepo;

  @tracked organizations = this.store.peekAll('organization');

  get level1Collapsed() {
    return this.level1Features.isEvery('enabled');
  }

  get level2Collapsed() {
    return this.level2Features.isEvery('enabled');
  }

  get level3Collapsed() {
    return this.level3Features.isEvery('enabled');
  }

  get addonsCollapsed() {
    return this.addonFeatures.isEvery('enabled');
  }

  get level1Features() {
    return this.featureOptions.filter(option => option.group === 'level-1');
  }

  get level2Features() {
    return this.featureOptions.filter(option => option.group === 'level-2');
  }

  get level3Features() {
    return this.featureOptions.filter(option => option.group === 'level-3');
  }

  get addonFeatures() {
    return this.featureOptions.filter(option => option.group === 'addon');
  }

  get featureOptions() {
    return getFeatureObjects(this.args.license).sortBy('label');
  }

  get saveDisabled() {
    return this.args.license.validations.isInvalid || !this.args.license.isDirty;
  }

  get confirmDisabled() {
    return this.args.isConfirming && isEmpty(this.args.changelog.notes);
  }

  searchOrganizations = task(waitFor(async (term) => {
    try {
      await timeout(300);
      return await this.organizationRepo.search.perform(term);
    } catch (e) {
      console.error(e);
    }
  }));
}

export default LicenseModal;
