import { typeOf } from '@ember/utils';
import { ObjectId } from 'bson';
import { getEmbeddedAlwaysRelationships } from 'eflex-license-manager/utils/ember-data';
import RESTSerializer, { EmbeddedRecordsMixin } from '@ember-data/serializer/rest';
import { inject as service } from '@ember/service';

export default class ApplicationSerializer extends RESTSerializer.extend(EmbeddedRecordsMixin) {
  @service store;

  primaryKey = '_id';

  serializeId(snapshot, json, primaryKey) {
    const { id } = snapshot;
    primaryKey ??= this.primaryKey;

    if (id) {
      json[primaryKey] = new ObjectId(id);
    }
  }

  serialize(snapshot, options) {
    const json = super.serialize(...arguments);

    if (options?.includeId) {
      this.serializeId(snapshot, json);
    }

    const { record, type } = snapshot;
    const embeddedAlwaysRelationships = getEmbeddedAlwaysRelationships(this.store, type);

    record.eachRelationship(
      function (name, descriptor) {
        switch (descriptor.kind) {
          case 'hasMany': {
            if (!embeddedAlwaysRelationships[name] && json[name] != null) {
              json[name] = json[name].map((item) => new ObjectId(item));
            }
            break;
          }

          case 'belongsTo': {
            if (!embeddedAlwaysRelationships[name] && json[name] != null) {
              json[name] = new ObjectId(json[name]);
            }
            break;
          }
        }
      },
      { json, embeddedAlwaysRelationships },
    );

    return json;
  }

  extractId(modelClass, resourceHash) {
    if (this._isLikelyObjectId(resourceHash)) {
      return resourceHash.toString();
    }

    // unpleasant workaround for embedded records, probably a better fix somewhere
    if (resourceHash.id != null && resourceHash[this.primaryKey] == null) {
      resourceHash[this.primaryKey] = new ObjectId(resourceHash.id);
      delete resourceHash.id;
    }

    return super.extractId(...arguments);
  }

  extractRelationship(relationshipModelName, relationshipHash) {
    if (relationshipHash == null) {
      return null;
    }

    if (typeOf(relationshipHash) === 'object' && this._isLikelyObjectId(relationshipHash)) {
      return {
        id: this.extractId(null, relationshipHash),
        type: relationshipModelName,
      };
    } else {
      return super.extractRelationship(...arguments);
    }
  }

  _isLikelyObjectId(obj) {
    if (obj?.toString == null) {
      return false;
    }
    return ObjectId.isValid(obj.toString());
  }
}
