import Product from 'eflex-license-manager/models/product';
import { belongsTo } from '@ember-data/model';

class Oee extends Product {
  @belongsTo('stationFeature', { async: false, inverse: null }) features;

  name = 'oee';
  label = 'OEE';
}

export default Oee;
