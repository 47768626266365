import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import DataViewController from 'eflex-license-manager/utils/data-view-controller';

export default class OrganizationController extends DataViewController {
  @tracked showOrganizationModal = false;

  get organization() {
    return this.model;
  }

  get sortableData() {
    return this.filteredLicenses;
  }

  get filteredLicenses() {
    return this.organization.licenses.reject((license) => license.isNew || license.isDeleted);
  }

  @action
  edit() {
    this.showOrganizationModal = true;
  }

  @action
  async save() {
    await this.organization.save();
    this.showOrganizationModal = false;
  }

  @action
  hideOrganizationModal() {
    this.organization.rollbackAttributes();
    this.showOrganizationModal = false;
  }
}
