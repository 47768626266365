import Model, { attr } from '@ember-data/model';
import { buildValidations, validator } from '@eflexsystems/ember-tracked-validations';

@buildValidations({
  notes: validator('presence', true),
  creator: validator('presence', true),
  creatorName: validator('presence', true),
})
class Changelog extends Model {
  @attr('objectId') creator;
  @attr('string') creatorName;
  @attr('string') notes;
  @attr('date', { defaultValue() { return new Date(); } }) createdAt;

  get isDirty() {
    return this.hasDirtyAttributes;
  }
}

export default Changelog;
