/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

class SearchBox extends Component {
  @tracked searchTerm;

  get currentTerm() {
    return this.searchTerm || this.args.searchTerm;
  }

  set currentTerm(value) {
    this.searchTerm = value;
  }

  @action
  search() {
    this.args.onSubmit?.(this.searchTerm);
  }

  @action
  clear() {
    this.currentTerm = null;
    this.args.onClear?.();
  }
}

export default SearchBox;
