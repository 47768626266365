import Model, { attr } from '@ember-data/model';

class AssemblyFeature extends Model {
  @attr('number', { defaultValue: 50 }) numberOfStations;
  @attr('boolean', { defaultValue: true }) statisticalReporting;

  get isDirty() {
    return this.hasDirtyAttributes;
  }

  get details() {
    return [
      { key: 'numberOfStations', label: 'Number of Stations', value: this.numberOfStations },
      { key: 'statisticalReporting', label: 'Reporting', value: this.statisticalReporting },
    ];
  }
}

export default AssemblyFeature;
