import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { buildValidations, validator } from '@eflexsystems/ember-tracked-validations';

@buildValidations({
  customer: validator('presence', true),
  project: validator('presence', true),
})
class LegacyLicense extends Model {
  @attr('string') licenseId;
  @attr('string') customer;
  @attr('string') project;
  @attr('string') jobNumber;
  @attr('string') greenKey;
  @attr('boolean', { defaultValue: false }) isVirtual;
  @attr('string') notes;
  @attr('date', { defaultValue() { return new Date(); } }) createdAt;
  @attr('date', { defaultValue() { return new Date(); } }) updatedAt;

  @hasMany('changelog', { async: false, cascadeDelete: true }) changelogs;

  @belongsTo('analytics', { async: false, inverse: null, singularize: false }) analytics;
  @belongsTo('assembly', { async: false, inverse: null }) assembly;
  @belongsTo('evision', { async: false, inverse: null }) evision;
  @belongsTo('jel', { async: false, inverse: null }) jel;
  @belongsTo('jem', { async: false, inverse: null }) jem;
  @belongsTo('kitting', { async: false, inverse: null }) kitting;
  @belongsTo('oee', { async: false, inverse: null }) oee;
  @belongsTo('quality', { async: false, inverse: null }) quality;
  @belongsTo('trackAndTrace', { async: false, inverse: null }) trackAndTrace;

  get isDirty() {
    return this.hasDirtyAttributes ||
      this.changelogs.isAny('isDirty') ||
      this.products.isAny('isDirty');
  }

  get hasProductExpirationWarning() {
    return this.products.isAny('expirationWarning');
  }

  get hasExpiredProduct() {
    return this.products.isAny('expired');
  }

  get products() {
    return [
      this.analytics,
      this.assembly,
      this.evision,
      this.jel,
      this.jem,
      this.kitting,
      this.oee,
      this.quality,
      this.trackAndTrace,
    ].compact();
  }

  get sortedChangelogs() {
    return this.changelogs.sortBy('createdAt');
  }

  async save(options) {
    await super.save(options);

    this.changelogs.toArray().forEach(changelog => {
      if (!changelog.isDirty) {
        return;
      }

      changelog.save();
    });
  }

  rollbackAttributes() {
    this.changelogs.toArray().forEach(changelog => {
      if (changelog.hasDirtyAttributes) {
        changelog.rollbackAttributes();
      }
    });

    this.products.forEach(product => {
      if (product.hasDirtyAttributes) {
        product.rollbackAttributes();
      }
    });

    super.rollbackAttributes(...arguments);
  }
}

export default LegacyLicense;
