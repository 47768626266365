/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { eflexFeatures } from 'eflex-license-manager/constants/eflex-features';

class LicenseDisplaySingle extends Component {
  get level1Features() {
    return eflexFeatures.filter(feature => feature.group === 'level-1');
  }

  get level2Features() {
    return eflexFeatures.filter(feature => feature.group === 'level-2');
  }

  get level3Features() {
    return eflexFeatures.filter(feature => feature.group === 'level-3');
  }

  get addonFeatures() {
    return eflexFeatures.filter(feature => feature.group === 'addon');
  }
}

export default LicenseDisplaySingle;
