import Model, { attr } from '@ember-data/model';

class StationFeature extends Model {
  @attr('number', { defaultValue: 50 }) numberOfStations;

  get isDirty() {
    return this.hasDirtyAttributes;
  }

  get details() {
    return [
      { key: 'numberOfStations', label: 'Number of Stations', value: this.numberOfStations },
    ];
  }
}

export default StationFeature;
