import Product from 'eflex-license-manager/models/product';
import { belongsTo } from '@ember-data/model';

class Evision extends Product {
  @belongsTo('evisionFeature', { async: false, inverse: null }) features;

  name = 'evision';
  label = 'eVision';

  get featuresJson() {
    return {
      numberOfCameras: this.features.numberOfCameras,
      reporting: this.features.reporting,
    };
  }
}

export default Evision;
