/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';

class LegacyLicenseModal extends Component {
  @service store;

  get saveDisabled() {
    return this.args.license.validations.isInvalid || !this.args.license.isDirty;
  }

  get confirmDisabled() {
    return this.args.isConfirming && isEmpty(this.args.changelog.notes);
  }
}

export default LegacyLicenseModal;
