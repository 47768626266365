import BsFormElement from 'ember-bootstrap/components/bs-form/element';
import { isEmpty } from '@ember/utils';
import DateTimePicker from 'eflex-license-manager/components/bs-form/element/control/date-time-picker';
import PowerSelectMultiple from 'eflex-license-manager/components/bs-form/element/control/power-select-multiple';
import PowerSelect from 'eflex-license-manager/components/bs-form/element/control/power-select';

export default class ValidatedBsFormElement extends BsFormElement {
  '__ember-bootstrap_subclass' = true;

  get hasValidator() {
    return !isEmpty(this._attrValidations);
  }

  get hasErrors() {
    return this._attrValidations?.isInvalid;
  }

  get errors() {
    return this._attrValidations?.messages;
  }

  get warnings() {
    return this._attrValidations?.warningMessages;
  }

  get _attrValidations() {
    return this.args.model?.validations?.attrs[this.args.property];
  }

  get controlComponent() {
    switch (this.controlType) {
      case 'date-time-picker': {
        return DateTimePicker;
      }
      case 'power-select': {
        return PowerSelect;
      }
      case 'power-select-multiple': {
        return PowerSelectMultiple;
      }
    }

    return super.controlComponent;
  }
}
