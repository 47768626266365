import { inject as service } from '@ember/service';
import DataViewRoute from 'eflex-license-manager/utils/data-view-route';

export default class OrganizationsRoute extends DataViewRoute {
  @service store;

  model(params) {
    const query = this._getSearchQuery(params);
    this.store.unloadAll('organization');
    return this.store.query('organization', query, { adapterOptions: params });
  }

  setupController(controller, model) {
    Object.assign(controller, {
      totalRecords: model.meta.recordCount ?? 0,
      model: this.store.peekAll('organization'),
    });
  }
}
