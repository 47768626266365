import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { waitFor } from '@ember/test-waiters';
import { task } from 'ember-concurrency';
import DataViewController from 'eflex-license-manager/utils/data-view-controller';
import DataView from 'eflex-license-manager/constants/data-view';

export default class OrganizationsController extends DataViewController {
  @service store;
  @service currentUser;
  @service router;

  @tracked sortByField = 'name';
  @tracked sortDirection = DataView.SORT_ASC;
  @tracked searchFields = ['name'];
  @tracked totalRecords = 0;

  @tracked showOrganizationModal = false;
  @tracked showDeleteConfirmModal = false;
  @tracked currentOrganization;

  get sortableData() {
    return this.organizations;
  }

  get hasErrors() {
    return this.model?.isAny('validations.isInvalid') ?? false;
  }

  get organizations() {
    return this.model.reject((mod) => mod.isNew || mod.isDeleted);
  }

  _saveOrganization = task(waitFor(async () => {
    await this.currentOrganization.save();
    this.showOrganizationModal = false;
    this.router.transitionTo('organization', this.currentOrganization.id);
  }));

  _hideOrganizationModal = task(waitFor(async () => {
    if (this.currentOrganization != null) {
      await this.currentOrganization.destroyRecord();
    }

    Object.assign(this, {
      showOrganizationModal: false,
      currentOrganization: null,
    });
  }));

  _confirmDelete = task(waitFor(async () => {
    if (this.currentOrganization != null) {
      await this.currentOrganization.destroyRecord();
    }

    Object.assign(this, {
      currentOrganization: null,
      showDeleteConfirmModal: false,
    });
  }));

  @action
  addOrganization() {
    const organization = this.store.createRecord('organization', { creator: this.currentUser.id });

    Object.assign(this, {
      currentOrganization: organization,
      showOrganizationModal: true,
    });
  }

  @action
  delete(organization) {
    Object.assign(this, {
      currentOrganization: organization,
      showDeleteConfirmModal: true,
    });
  }

  @action
  cancelDelete() {
    Object.assign(this, {
      currentOrganization: null,
      showDeleteConfirmModal: false,
    });
  }

  @action
  rollback(record) {
    record.rollbackAttributes();
  }

  @action
  transitionToOrganization(organization) {
    this.router.transitionTo('organization', organization);
  }
}
