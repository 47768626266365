import Product from 'eflex-license-manager/models/product';
import { belongsTo } from '@ember-data/model';

class Quality extends Product {
  @belongsTo('stationFeature', { async: false, inverse: null }) features;

  name = 'quality';
  label = 'Quality';
}

export default Quality;
