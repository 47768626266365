export const getEmbeddedAlwaysRelationships = function (store, type) {
  const serializer = store.serializerFor(type.modelName);

  const embedded = {};
  type.eachRelationship(
    function (name, descriptor) {
      const relationshipType = type.determineRelationshipType(descriptor, store);

      if (serializer.hasEmbeddedAlwaysOption(name)) {
        embedded[name] = {
          relationshipType,
          descriptor,
        };
      }
    },
    { serializer },
  );

  return embedded;
};

export const getCascadeDeletedRecords = function (record) {
  if (record == null) {
    return [];
  }

  let recordsToUnload = [];

  record.eachRelationship(function (name, descriptor) {
    const { options, kind, name: descName } = descriptor;

    if (!options.cascadeDelete) {
      return;
    }

    if (kind === 'hasMany') {
      const hasManyRecords = record.get(descName).toArray();
      recordsToUnload = recordsToUnload.concat(hasManyRecords);
    } else if (kind === 'belongsTo') {
      const belongsToRecords = record.get(descName);
      recordsToUnload = recordsToUnload.concat([belongsToRecords]);
    }
  });

  return recordsToUnload;
};

export default {
  getEmbeddedAlwaysRelationships,
  getCascadeDeletedRecords,
};
