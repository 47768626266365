import ApplicationSerializer from 'eflex-license-manager/serializers/application';

export default class LegacyLicenseSerializer extends ApplicationSerializer {
  attrs = {
    evision: {
      embedded: 'always',
    },
    trackAndTrace: {
      embedded: 'always',
    },
    jem: {
      embedded: 'always',
    },
    jel: {
      embedded: 'always',
    },
    oee: {
      embedded: 'always',
    },
    assembly: {
      embedded: 'always',
    },
    kitting: {
      embedded: 'always',
    },
    quality: {
      embedded: 'always',
    },
    analytics: {
      embedded: 'always',
    },
  };
}
