import { inject as service } from '@ember/service';
import DataViewRoute from 'eflex-license-manager/utils/data-view-route';

export default class LicenseRoute extends DataViewRoute {
  @service store;

  model(params) {
    return this.store.findRecord('license', params.id);
  }
}
