import Model, { attr } from '@ember-data/model';

class EvisionFeature extends Model {
  @attr('number', { defaultValue: 50 }) numberOfCameras;
  @attr('boolean', { defaultValue: true }) reporting;

  get isDirty() {
    return this.hasDirtyAttributes;
  }

  get details() {
    return [
      { key: 'numberOfCameras', label: 'Number of Cameras', value: this.numberOfCameras },
      { key: 'reporting', label: 'Reporting', value: this.reporting },
    ];
  }
}

export default EvisionFeature;
