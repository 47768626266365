import Model, { attr } from '@ember-data/model';
import { eflexFeatures } from 'eflex-license-manager/constants/eflex-features';

class Feature extends Model {
  @attr('string') key;
  @attr('string') group;
  @attr('boolean', { defaultValue: true }) enabled;

  get isDirty() {
    return this.hasDirtyAttributes;
  }

  get label() {
    return eflexFeatures.findBy('key', this.key)?.label;
  }
}

export default Feature;
