import { ObjectId } from 'bson';
import { inject as service } from '@ember/service';
import pProps from 'p-props';
import AuthenticatedRoute from 'eflex-license-manager/utils/authenticated-route';

export default class OrganizationRoute extends AuthenticatedRoute {
  @service store;

  async model(params) {
    return await pProps({
      licenses: this.store.query('license', { organization: new ObjectId(params.id) }),
      organization: this.store.findRecord('organization', params.id),
    });
  }

  setupController(controller, { licenses, organization }) {
    Object.assign(controller, {
      totalRecords: licenses.meta.recordCount ?? 0,
      model: organization,
    });
  }
}
