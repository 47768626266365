import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import DataView from 'eflex-license-manager/constants/data-view';
import { range } from 'ramda';

export default class DataViewController extends Controller {
  queryParams = [
    'page',
    'perPage',
    'sortByField',
    'sortDirection',
    'searchTerm',
    'searchFields',
  ];

  @tracked page = 1;
  @tracked perPage = 10;
  @tracked sortByField;
  @tracked sortDirection = DataView.SORT_DESC;
  @tracked searchTerm;
  @tracked searchFields = [];
  @tracked sortableData;
  @tracked totalRecords;

  ascIcon = 'long-arrow-alt-down';
  descIcon = 'long-arrow-alt-up';

  get sortByDescending() {
    return this.sortDirection === DataView.SORT_DESC;
  }

  get sortByIcon() {
    if (this.sortByDescending) {
      return this.descIcon;
    } else {
      return this.ascIcon;
    }
  }

  get sortedData() {
    if (this.sortByField == null) {
      return this.sortableData;
    }

    const sorted = this.sortableData.sortBy(this.sortByField);

    if (this.sortByDescending) {
      return sorted.reverse();
    } else {
      return sorted;
    }
  }

  get totalPages() {
    return Math.ceil(this.totalRecords / this.perPage);
  }

  get pageButtons() {
    let startPage;
    let endPage;

    if (this.totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = this.totalPages;
    } else if (this.page <= 6) {
      // more than 10 total pages so calculate start and end pages
      startPage = 1;
      endPage = 10;
    } else if (this.page + 4 >= this.totalPages) {
      startPage = this.totalPages - 9;
      endPage = this.totalPages;
    } else {
      startPage = this.page - 5;
      endPage = this.page + 4;
    }

    return range(startPage, endPage + 1);
  }

  reset() {
    Object.assign(this, {
      page: 1,
      sortByField: null,
      sortDirection: DataView.SORT_DESC,
      searchTerm: null,
    });
  }

  @action
  sortBy(field) {
    if (this.sortByDescending) {
      this.sortDirection = DataView.SORT_ASC;
    } else {
      this.sortDirection = DataView.SORT_DESC;
    }

    this.sortByField = field;
  }

  @action
  search(term) {
    this.page = 1;
    this.searchTerm = term;
  }

  @action
  clear() {
    this.reset();
  }
}
