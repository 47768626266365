const DecryptionKey = `-----BEGIN RSA PRIVATE KEY----- \
MIIEowIBAAKCAQEA98IPEvfCsAzakkWIBxX7kfFiG1tctdQLat8M3tHjqcIi2TL5 \
ONCZehEjxWM54UOObWyHoflaUBZT9+ljlTYbSchUjAtL5gF2ar3feqiS9W0K+L37 \
t4aMl4WEOXaZCBw5SI1+RtggFvEotSUce6rjVlIwIr4cx8Eb+ejUGD9mdurGvLk1 \
xE5CEhn4HiFluibeqBgTLrsZm+0ktqM3sxeIe0PJ39Ar4/q0LEuG4eqzYZNREQVp \
I8Cmb+YvVv/Cx5gk5Jhp73M8/6Xd4PZ2xPAoTjAqTQtn8U71SEQP28/rSjPNVRda \
V6zB0dcAl4byhozvEyJECaBRJv8omN5h8JyKRwIDAQABAoIBAFQL6MEue0Ttl1f3 \
bmW491ZS5E0TeSfVfuoKMHuGCA0WZmv39sNnQClAlznmp0Pbfv1vy7MOu6xfZqy6 \
56U+bZNh+gxn6J9l753WO3GIrhHDo+0txa5EXn0oNeqoaNFvgZ8zIJs3xesCdCS/ \
KiJpniUyTFH83BaHU847RXfUShYcc90yBHgme8WPyej0tRUr6YqVEqO9Ryk1tPk/ \
4u9+fQ+xtfhJB/1jTO6zJ+GU9xWAnPyufr0QXKMtjp/rproJAmPh6qBEYUkEXqeD \
yctqRb6wO38Ml2u4tPqeUxfouw6vN1W/9n6FxFYSdTDgEzJE/EPRfPFwH+7YnxMC \
MEUAdgECgYEA/tbEbY4Uo40Vw/sk1tyx19eYLlcUHNRb+0KRkWIfhFvQHMuyUbB8 \
rxx1dCBNkvNZL/VaheETSdNm9t1cRjmLJKhmuGAaDJULsWGJt7yRrIydNnnCakfJ \
nX+whYKnhcho+7h6cdYUTpH5GkPFRV+qb8gliGB4XT2x5A6CATIXCqcCgYEA+OMI \
YmUUcy1HFqZdZfFAWV7up0wXnbrMamjd1JFAdjAMKSP5AFtXlEjbbrqmJY+T6snq \
3xL4PDyTSCodAfhzqcVPdyGNrenBDVEPgZkiUOjXGH9FanOhJ6iqOmiulJY3c1D9 \
rUp1gQXwAnozrGNQIuNJXiA1S1g1EHOALLS/l2ECgYBm45dTFDZJAkFzawaoqVo0 \
ZxtOOmIocaThgTL8m71V1yvjNGq+iJ8OpFLXm+M2HYI41rrBqun6uJutuBn1UG5Z \
UpSGhGaABf0+lL7hrAS0weEHiYlxF6LTMCa5ANNaNFwOpaDqLSDtyAnnDM2tAL7z \
er+MnEZ8ftg/14utp+edqQKBgQDjBhSrzA/vT6m8m1wulPVhkBqZN+uC0NWNasKO \
F0B5jjJJhKqpDKjCdgLS6iaLtH/0duJrQN5187tU5DU5iuvWPEjUHiMiNEsIXpyK \
VqjarMkna1BiXb7ucgd05+/3UV6bLZF39KdiXYwkdluUL7L1X+WYmkCCzZ75tqGA \
Jg9tgQKBgG6fpGEYrg4XZooie69cYbfp9l8r2uga5EPUV6gykIeJGFFUK93EUS7v \
T6t7Pn/8neoMPKzoslFLlnEI+fXJm/uktN7qBm3INRtigzYa8NgpfQNFbCzayj+Q \
elkciGXdXjsLgetvr8W+ZxxX8+zJnpSdsE98bfrgeqdYqWtaO3xL \
-----END RSA PRIVATE KEY-----`;

export default DecryptionKey;
