/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import { task } from 'ember-concurrency';
import moment from 'moment';

class LegacyLicenseDisplay extends Component {
  @service store;
  @service currentUser;
  @service legacyLicenseEncrypter;
  @service router;

  @tracked searchTerm;
  @tracked showLicenseModal = false;
  @tracked showChangelogModal = false;
  @tracked showDeleteConfirmModal = false;
  @tracked isEditing = false;
  @tracked isConfirming = false;
  @tracked currentChangelog = null;
  @tracked currentLicense = null;

  get totalRecords() {
    return this.args.totalRecords;
  }

  _reset() {
    if (!this.args.singleLicense) {
      this.currentLicense = null;
    }

    Object.assign(this, {
      currentChangelog: null,
      showLicenseModal: false,
      showChangelogModal: false,
      showDeleteConfirmModal: false,
      isConfirming: false,
      isEditing: false,
    });
  }

  _saveLicense = task(waitFor(async () => {
    try {
      if (this.currentChangelog != null) {
        await this.currentChangelog.save();
      }

      await this.currentLicense.save();

      this._reset();
    } catch (e) {
      console.error(e);
    }
  }));

  _getNewLicenseId() {
    return moment().format('YYMMDDHHmmss');
  }

  @action
  addLicense() {
    const analytics = this.store.createRecord('analytics');
    analytics.features = this.store.createRecord('analyticsFeature');

    const assembly = this.store.createRecord('assembly');
    assembly.features = this.store.createRecord('assemblyFeature');

    const evision = this.store.createRecord('evision');
    evision.features = this.store.createRecord('evisionFeature');

    const jel = this.store.createRecord('jel');
    jel.features = this.store.createRecord('stationFeature');

    const jem = this.store.createRecord('jem');
    jem.features = this.store.createRecord('stationFeature');

    const kitting = this.store.createRecord('kitting');
    kitting.features = this.store.createRecord('stationFeature');

    const oee = this.store.createRecord('oee');
    oee.features = this.store.createRecord('stationFeature');

    const quality = this.store.createRecord('quality');
    quality.features = this.store.createRecord('stationFeature');

    const trackAndTrace = this.store.createRecord('trackAndTrace');
    trackAndTrace.features = this.store.createRecord('stationFeature');

    const license = this.store.createRecord('legacyLicense', {
      licenseId: this._getNewLicenseId(),
      analytics,
      assembly,
      evision,
      jel,
      jem,
      kitting,
      oee,
      quality,
      trackAndTrace,
    });

    const changelog = this.store.createRecord('changelog', {
      creator: this.currentUser.id,
      creatorName: this.currentUser.creatorName,
      notes: 'License Created',
    });

    license.changelogs.addObject(changelog);

    Object.assign(this, {
      currentLicense: license,
      showLicenseModal: true,
    });
  }

  @action
  edit(license) {
    Object.assign(this, {
      currentLicense: license,
      showLicenseModal: true,
      isEditing: true,
    });
  }

  @action
  showChangelogs(license) {
    Object.assign(this, {
      currentLicense: license,
      showChangelogModal: true,
    });
  }

  @action
  hideChangelogs() {
    this._reset();
  }

  @action
  async continueSave() {
    if (!this.isEditing) {
      await this._saveLicense.perform();
      this.router.refresh();
      return;
    }

    const changelog = this.store.createRecord('changelog', {
      creator: this.currentUser.id,
      creatorName: this.currentUser.creatorName,
    });

    this.currentLicense.changelogs.addObject(changelog);
    this.currentLicense.updatedAt = changelog.createdAt;

    Object.assign(this, {
      currentChangelog: changelog,
      isConfirming: true,
    });
  }

  @action
  async confirmSave() {
    await this._saveLicense.perform();
  }

  @action
  async hideAddEditModal() {
    this.showLicenseModal = false;

    if (this.currentLicense?.isNew) {
      await this.currentLicense.destroyRecord({});
    } else {
      this.currentLicense.rollbackAttributes();
    }

    if (this.currentChangelog?.isNew) {
      await this.currentChangelog.destroyRecord({});
    }

    this._reset();
  }

  @action
  delete(license) {
    Object.assign(this, {
      currentLicense: license,
      showDeleteConfirmModal: true,
    });
  }

  @action
  async confirmDelete() {
    await this.currentLicense.destroyRecord({});
    this._reset();

    if (this.args.singleLicense) {
      this.router.transitionTo('legacyLicenses');
    }
  }

  @action
  cancelDelete() {
    this._reset();
  }

  @action
  async download(license) {
    await this.legacyLicenseEncrypter.downloadLicense.perform(license);
  }
}

export default LegacyLicenseDisplay;
