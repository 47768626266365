import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import config from 'eflex-license-manager/config/environment';

export default class ApplicationRoute extends Route {
  @service mongodbRealm;
  @service session;

  async beforeModel() {
    await this.session.setup();

    if (this.mongodbRealm.apiKeyAuth) {
      await this.session.authenticate('authenticator:mongodb-realm', { apiKey: config.mongodbRealm.apiKey });
    }
  }
}
