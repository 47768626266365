/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { tracked } from '@glimmer/tracking';

class OptionsCallout extends Component {
  @tracked collapsedProp;

  get collapsed() {
    if(isEmpty(this.collapsedProp)) {
      return this.args.collapsed;
    }

    return this.collapsedProp;
  }

  set collapsed(value) {
    this.collapsedProp = value;
  }

  get showCheckAll() {
    return !isEmpty(this.args.checkAll);
  }

  get allChecked() {
    return this.args.options?.isEvery('enabled');
  }

  @action
  checkAll(e) {
    const { name, checked } = e.currentTarget;
    this.args.checkAll?.(name, checked);
  }

  @action
  change(e) {
    const { name, checked } = e.currentTarget;
    this.args.optionChanged?.(name, checked);
  }
}

export default OptionsCallout;
