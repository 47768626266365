import AuthenticatedRoute from 'eflex-license-manager/utils/authenticated-route';

export default class DataViewRoute extends AuthenticatedRoute {
  queryParams = {
    page: {
      refreshModel: true,
    },
    perPage: {
      refreshModel: true,
    },
    sortByField: {
      refreshModel: true,
    },
    sortDirection: {
      refreshModel: true,
    },
    searchTerm: {
      refreshModel: true,
    },
    searchFields: {
      refreshModel: true,
    },
  };

  _getSearchQuery(params) {
    if (params.searchTerm != null && params.searchFields?.length > 0) {
      const searchQueryOrs = params.searchFields.map((field) => ({
        [field]: new RegExp(params.searchTerm, 'i'),
      }));

      return { $or: searchQueryOrs };
    }

    return {};
  }
}
