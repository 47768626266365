import Service from '@ember/service';
import { waitFor } from '@ember/test-waiters';
import { dasherize } from '@ember/string';
import { task, taskGroup } from 'ember-concurrency';
import jose from 'node-jose';
import saveAs from 'file-saver';
import EncryptionKey from 'eflex-license-manager/utils/keys/encryption-key';
import DecryptionKey from 'eflex-license-manager/utils/keys/decryption-key';

export default class LicenseEncrypter extends Service {
  keystore;
  @taskGroup({ enqueue: true }) encrypt;

  constructor() {
    super(...arguments);
    this.keystore = jose.JWK.createKeyStore();
  }

  _sanitizeString(string) {
    return string
      .replace(/[^\dA-z-]|\^|`/g, '')
      .replace(/-{2,}/g, '-')
      .replace(/-+$/, '');
  }

  serializeLicense(license) {
    const json = {
      licenseId: license.licenseId,
      expirationDate: license.expirationDate,
      label: license.label,
      jobNumber: license.jobNumber,
      stationLimit: license.stationLimit,
      organization: {
        name: license.organization.name,
        expirationThreshold: license.organization.expirationThreshold,
      },
    };

    json.features = license.features.map((feature) => ({
      key: feature.key,
      group: feature.group,
      enabled: feature.enabled,
    }));

    return JSON.stringify(json);
  }

  encodeLicense(license) {
    const base64license = jose.util.base64url.encode(license);
    return jose.util.base64url.decode(base64license);
  }

  downloadTextFile(text, filename) {
    const fileBlob = new Blob([text], { type: 'text/plain' });
    saveAs(fileBlob, filename);
  }

  getLicenseFilename(license) {
    const safeOrgName = this._sanitizeString(dasherize(license.organization.name));
    const safeLabel = this._sanitizeString(dasherize(license.label));

    return `${safeOrgName}_${safeLabel}_${license.licenseId}.txt`;
  }

  addEncryptionKey = task(waitFor(async () => {
    return await this.keystore.add(EncryptionKey, 'pem');
  }));

  addDecryptionKey = task(waitFor(async () => {
    return await this.keystore.add(DecryptionKey, 'pem');
  }));

  encryptLicense = task({ group: 'encrypt' }, waitFor(async (license) => {
    const encryptionKey = await this.addEncryptionKey.perform();
    const serializedLicense = this.serializeLicense(license);
    const encodedLicense = this.encodeLicense(serializedLicense);

    const encrypter = jose.JWE.createEncrypt({ format: 'compact' }, encryptionKey);
    return await encrypter.update(encodedLicense).final();
  }));

  decryptLicense = task({ group: 'encrypt' }, waitFor(async (encryptedText) => {
    const decryptionKey = await this.addDecryptionKey.perform();

    let decryptedJWE;
    try {
      decryptedJWE = await jose.JWE
        .createDecrypt(decryptionKey)
        .decrypt(encryptedText);
    } catch(e) {
      console.error(e);
      return null;
    }

    return JSON.parse(decryptedJWE.payload.toString());
  }));

  downloadLicense = task(waitFor(async (license) => {
    try {
      const encryptedLicense = await this.encryptLicense.perform(license);
      const filename = this.getLicenseFilename(license);
      this.downloadTextFile(encryptedLicense, filename);
    } catch (e) {
      console.error(e);
    }
  }));
}
